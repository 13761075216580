:root {
    --color-primary: #152255;
    --color-primary-light: #273A80;
    --color-primary-dark: #181C32;
    --color-black: #000;
    --color-white: #fff;
    --color-grey-100: #E1E1E1;
    --color-grey-200: #EFEFEF;
    --color-grey-300: #84858B;
    --color-danger: #C62828;
    --color-danger-light: #E53935;
    --color-danger-dark: #B71C1C;
    --color-text: #ECEFF1;
    --color-text-dark: #3d3d3d;
    --color-secondary: #b9a773;
    --color-secondary-light: #d6c388;
    --color-secondary-dark: #8a7a51;
    --color-table: #f3f3f6;
    --color-disabled-form-control: #f3f3f6;
    --color-table-dark: #d8d8d8;
    --color-form: #4b090f;
    --color-warning: #ffa800;
    --color-confirm: rgb(13, 175, 13);
    --color-green: (#11A443);
    --ckeditor-height: 100%;
    --default-border-radius: 8px;
    --box-shadow-default: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    --list-container-margin-top: 70px;
}