.ngx-pagination {
    display: flex;
    gap: 1rem;
    height: 2.2rem;

    li {
        display: flex;
        align-items: center;

        &:not(.pagination-previous):not(.pagination-next) a {
            padding: 0.36rem 1rem !important;
            border-radius: calc(var(--default-border-radius) / 2);

        }
    }

    .pagination-previous,
    .pagination-next {
        display: flex;
        align-items: center;

        span {
            display: none;
        }

        a {
            content: "";
        }

        &:before {
            font-size: 2rem;
        }
    }

    .pagination-previous {
        &:not(.disabled) {
            a {
                /* Hide the text. */
                text-indent: 100%;
                white-space: nowrap;
                overflow: hidden;
                position: relative;
                padding: .5rem;
                width: 32.5px;

                &:hover {
                    border-radius: calc(var(--default-border-radius) / 2);
                }

                &:after {
                    content: "\2039" !important;
                    font-size: 2.4rem;
                    position: absolute;
                    left: 3px;
                    top: -8px;
                }
            }
        }

        &.disabled {
            &:after {
                content: "\2039" !important;
                font-size: 2.4rem;
            }

            &:before {
                content: "" !important;
            }
        }
    }

    .pagination-next {
        &:not(.disabled) {
            a {
                /* Hide the text. */
                text-indent: 200%;
                white-space: nowrap;
                overflow: hidden;
                position: relative;
                padding: .5rem;

                &:hover {
                    border-radius: calc(var(--default-border-radius) / 2);
                }

                &:after {
                    content: "\203A" !important;
                    font-size: 2.4rem;
                    position: absolute;
                    left: -7px;
                    top: -8px;
                }
            }
        }

        &.disabled {
            &:after {
                content: "\203A" !important;
                font-size: 2.4rem;
            }
        }
    }

    .current {
        background: var(--color-primary-light) !important;
        padding: .36rem 1rem !important;
        border-radius: calc(var(--default-border-radius) / 2);
    }
}