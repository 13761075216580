.ck.ck-editor__main>.ck-editor__editable {
    height: var(--ckeditor-height);
}

ckeditor-output img {
    max-width: 100%;
    height: auto;
}

.ck-body-wrapper{
    z-index: 10000;
}

.ck-toolbar__separator{
    background: none !important;
    width: 30px !important;
}

.ck-editor__editable {
    min-height: 200px;
}