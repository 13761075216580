.gardium-grid {
    width: 100%;
    margin: 0 auto;
    margin-top: 1%;
    border: 1px solid var(--color-table-dark);

    .ag-root-wrapper-body {
        min-height: 400px;
    }
}

.gardium-small-grid {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    margin-top: 1%;
    border: 1px solid var(--color-table-dark);
}

.gardium-wizzard-grid {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    margin-top: 1%;
    border: 1px solid var(--color-table-dark);
    text-align: left;
}

.ag-theme-material .ag-header,
.ag-theme-material .ag-header-cell {
    font-weight: bolder;
    color: var(--color-primary-light);
    font-size: 14px;
    font-family: Poppins, sans-serif;
    background-color: var(--color-table);
    line-height: 21px;
}

.ag-row-hover {
    cursor: pointer;
}

.ag-body-viewport .ag-center-cols-container {
    color: #817cac;
}

.ag-header-cell-label .ag-header-cell-text {
    height: auto !important;
    overflow-wrap: normal !important;
    white-space: normal !important;
    overflow: unset !important;
}

.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
    padding-left: 7px !important;
    padding-right: 7px !important;
}

.hide-pagination .ag-paging-panel {
    display: none !important;
}