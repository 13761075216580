.filtering-button {
	padding-right: 32px !important;
	padding-left: 32px !important;
	padding-top: 32px !important;
	padding-bottom: 32px !important;
	width: 256px !important;
	height: 160px;
	box-shadow: 0px 1px 15px rgba(69, 65, 78, 0.1);
	border-radius: 8px;
	background-color: var(--color-grey-200);
	border-color: transparent;
	display: flex;
	flex-direction: column;
	align-items: left;
	position: relative;

	app-svg-icon {
		color: var(--color-primary-light);
		width: 32px;
		height: 32px;

		svg {
			width: 100% !important;
			height: 100% !important;
			
			path {
				fill: currentColor !important;
			}
		}
	}

	h2 {
		text-align: left;
	}

	p.details-text {
		text-align: left;
	}

	&.gold-on-gray {
		app-svg-icon, h2 {
			color: var(--color-secondary) !important;
		}
	}
	
	&.inverted {
		background-color: var(--color-primary-light) !important;

		app-svg-icon, h2, p.details-text {
		  color: var(--color-white) !important;
		}

		&:hover, &.selected {
			background-color: var(--color-secondary) !important;
		
			h2, p.details-text, app-svg-icon {
			  color: var(--color-primary-light) !important;
		  }
		}
	}

	&:hover:not(.inverted), &.selected:not(.inverted) {
		background-color: var(--color-primary-light) !important;
	
		h2, p.details-text, app-svg-icon {
		  color: var(--color-white) !important;
	  }
	}

	.btn-group {
		top: 16px;
		right: 16px;
		position: absolute;

		.custom-dropdown-button {
			background-color: transparent;
			border-color: transparent;
		
			i.settings {
				color: var(--color-secondary);
				font-size: 1.5rem;
			}
		}
	}
}