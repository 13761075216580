.card-body {
	padding: 2rem 2.25rem;
}

.btn-delete {
	font-size: 1.75em;
	color: var(--color-danger);
	background-color: transparent;
	border-color: transparent;
}

.btn-save {
	font-size: 1.75em;
	color: var(--color-primary-light);
	background-color: transparent;
	border-color: transparent;
}

.btn-quit {
	font-size: 1.25em;
	color: var(--color-white);
	background-color: var(--color-danger);
	border-color: transparent;
	border-radius: 5px;
	vertical-align: middle;
}

.btn-danger {
	border-radius: 5px;
}

.btn-next {
	font-size: 1.75em;
	color: var(--color-primary-light);
	background-color: transparent;
	border-color: transparent;
}

.btn-back {
	font-size: 1.75em;
	color: var(--color-primary-light);
	background-color: transparent;
	border-color: transparent;
}

.wizard-btn-text {
	color: #464E5F;
	font-size: 13px;
	padding-right: 8px;
	padding-left: 8px;
}

.wizard-btn {
	display: flex;
	align-items: center;
	width: 332px;
}

.status {
	padding: 0.5em 32px;
	color: var(--color-primary-dark);
	font-weight: 600;
	background-color: var(--color-secondary);
}

.btn-next:disabled {
	opacity: 0.4;
}

.btn-back:disabled {
	opacity: 0.4;
}

.align-left {
	text-align: left !important;
}

.margin-top-20 {
	margin-top: 20px;
}

.margin-bottom-20 {
	margin-bottom: 20px;
}

.margin-left-20 {
	margin-left: 20px;
}

.margin-left-10 {
	margin-left: 10px;
}

.margin-right-20 {
	margin-right: 20px;
}

.margin-right-5 {
	margin-right: 5px;
}

.margin-bottom-minus-10 {
	margin-bottom: -10px;
}

.margin-bottom-10 {
	margin-bottom: 10px;
}

.margin-left-38 {
	margin-left: 38%
}

.margin-left-42 {
	margin-left: 42%
}

.padding-right-5 {
	padding-right: 5px;
}

.inline-block {
	display: inline-block;
}

.block {
	display: block;
}

.gardium-wizzard-grid {
	height: 400px;
}

.text-alert {
	color: var(--color-danger);
}

.flex {
	display: flex;
}

.form-inline .form-control {
	width: 100% !important;
}