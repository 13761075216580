.aside-fixed .aside,
#kt_aside {
    background-color: var(--color-primary);
    color: var(--color-white);

    .menu-state-title-primary,
    .menu-title-gray-800 {
        .menu-item .menu-link {

            &.active {
                color: var(--color-secondary) !important;

                .menu-icon i {
                    color: inherit !important;
                }
            }

            .menu-icon i {
                color: inherit !important;
            }

            &,
            .menu-title {
                color: inherit !important;
            }
        }
    }
}

.gardium-title {
	color: var(--color-primary-light);
	font-weight: bold;
}

.details-text {
	color: var(--color-grey-300);
	font-size: 16px;
	font-style: italic;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: left;
}

.gardium-link {
    font-weight: bold;
    align-self: center;
    transition: none;
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-primary-light);
    
    &:hover:not(.big-link) {
        text-decoration: underline !important;
        color: var(--color-primary-light);
        transition: none !important;
    }
    
    &.big-link {
        color: var(--color-primary);
        text-decoration: none;
        border-bottom: 2px solid var(--color-primary);

        &:hover, &:active {
            color: var(--color-primary-light);
            border-bottom: 2px solid var(--color-primary-light);
        }
    }

    &.gold {
        color: var(--color-secondary);
        border-bottom: 2px solid var(--color-secondary);
    }
}