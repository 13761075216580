/* You can add global styles to this file, and also import other style files */
@import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
@import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material.css';
@import "styles/styles";

/*Validator style error*/
.has-danger .form-control-feedback {
    color: var(--color-white) !important;
    background-color: var(--color-danger-light);
    border-radius: 0px 0px 6px 6px;
    padding-left: 1%;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.asterix {
    color: var(--color-danger);
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.descriptionFAQ>div>img {
    width: 25%;
    height: 25%;
}

img.sizeEditor {
    width: 25%;
    height: 25%;
}

.grid-pagination {
    text-align: end;
}

.grid-pagination-space {
    padding-top: 10px;
}

@media screen and (max-width:414px) {
    img.sizeEditor {
        width: 12%;
        height: 12%;
    }
}

@media (max-width: 414px) {
    ul.steps-indicator>li>a>div.label {
        font-size: 9px;
        margin-left: -11px;
    }

    .horizontal.small>ul.steps-indicator>li>a>div.step-indicator {
        left: calc(50% - 11px);
    }
}

@media (min-width: 415px) and (max-width: 1024px) {
    .btn-invoicDetails {
        margin-right: 18px;
    }
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.width-percent-5 {
    width: 5%;
}

.width-percent-10 {
    width: 10%;
}

.width-percent-15 {
    width: 15%;
}

.width-percent-20 {
    width: 20%;
}

.width-percent-30 {
    width: 30%;
}

.width-percent-40 {
    width: 40%;
}

.width-percent-50 {
    width: 50%;
}

.width-percent-100 {
    width: 100%;
}

/* Sweet alert */

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

.swal2-icon.swal2-info::before,
.swal2-icon.swal2-warning::before,
.swal2-icon.swal2-success::before,
.swal2-icon.swal2-error::before {
    content: "";
}

@keyframes glowing {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

.glowing {
    animation: glowing 2s infinite;
}

.root-initial-loader .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 99999 !important;
    width: 100%;
    height: 100%;
}

.root-initial-loader i {
    position: absolute;
    color: rgb(91, 167, 234);
    z-index: 100000 !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.root-initial-loader .fa-spin-2x {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}

ngx-spinner .loading-text {
    font-weight: bold;
    color: white;
    font-size: 1.3em;
}

bs-days-calendar-view+bs-days-calendar-view {
    margin-left: 10px;
}

bs-days-calendar-view {
    display: inline-block;
    border-radius: 4px 0 0 4px;
}

@media (max-width: 768px) {
    .btn-md-icon {
        margin-right: 0 !important;
        padding-right: 0 !important;
    }
}

ul.nav-tabs {
    margin-bottom: 0px !important;
}

@media screen and (min-width: 1200px) {
    .m-grid__item.m-grid__item--fluid.m-wrapper {
        margin: 0 1% 0 1% !important;
    }
}

@media screen and (min-width: 1400px) {
    .m-grid__item.m-grid__item--fluid.m-wrapper {
        margin: 0 3% 0 3% !important;
    }
}

@media screen and (min-width: 1600px) {
    .m-grid__item.m-grid__item--fluid.m-wrapper {
        margin: 0 5% 0 5% !important;
    }
}

body .ui-breadcrumb {
    border: none;
}

.tab-content .card {
    border-top-left-radius: 0;
}

.nav-tabs .nav-link.active {
    border-color: var(--color-white) !important;
}

.btn-primary {
    box-shadow: none !important;
}

ul.steps-indicator li a .label {
    width: 100%;
    background-color: transparent;
}

.separator-right {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.justify-evenly {
    display: flex;
    justify-content: space-between;
}

.justify-end {
    display: flex;
    justify-content: flex-end;
}

.svg-wrapper svg {
    width: 100%;
    height: 100%;
    
    path {
        fill: currentColor !important;
    }
}