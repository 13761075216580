@import "../../app/requests/request-wizzard-creator/wizzard-request/wizzard-request.component.scss";
@import "./theme.scss";

/* General */

.flex-column-fluid {
    flex: 1 0 auto;
    padding: 25px 0 !important;
}

div#kt_login {
    padding: 0 !important;
}

div#kt_header {
    box-shadow: none;
}

.listContainer {
    margin-top: var(--list-container-margin-top) !important;

    &+& {
        margin-top: 24px !important;
    }
}

.breadCrumbContainer {
    --list-container-margin-top: 68px;
    margin: var(--list-container-margin-top) 0 0;
}

//fix header after migration aspnet 11.2
.subheader.subheader-solid.toolbar {
    --left-position: 265px;
    position: fixed;
    height: 54px;
    top: 65px;
    left: var(--left-position);
    right: 0;
    z-index: 95;
    box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
    background-color: var(--color-white);
    border-top: 1px solid #ecf0f3;
    margin-bottom: 2rem;
    padding-bottom: 3rem;
    transition: left .3s ease, top .3s ease;

    body[data-kt-aside-minimize="on"] & {
        --left-position: 75px;
    }
}

.card-body {
    padding: 40px 56px;

    h2,
    &__title {
        color: var(--color-primary-light)
    }
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-auto {
    padding-right: 12.5px;
    padding-left: 12.5px;
}

input[type="checkbox"]:checked {
    background: var(--color-primary-light) !important;
    color: white;
}

body .p-checkbox {
    border: 1px solid #a6a6a6;
    background-color: var(--color-white);
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 3px;
    transition: background-color .2s, border-color .2s, box-shadow .2s;
}

.p-checkbox {
    margin: 0 0.5em 0 0;
}

.p-checkbox-icon.pi.pi-check {
    margin: 1px 0px 1px 1px;
    font-size: 12px;
}

.p-tree {
    padding: 0.571em 1em;
}

.p-tree-toggler-icon.pi.pi-fw.pi-chevron-down,
.pi-chevron-right {
    vertical-align: middle;
    display: inline-block;
    float: none;
    margin: 0 0.143em 0 0;
    color: #848484;
}

input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: lightgray;
    height: 20px;
    width: 20px;
    border: 1px solid white;
    color: white;
}

input[type="checkbox"]:after {
    content: ' ';
    position: relative;
    left: 40%;
    top: 20%;
    width: 25%;
    height: 40%;
    border: solid var(--color-white);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
}

input[type="checkbox"]:checked:after {
    display: block;
}

.alert.alert-warning {
    background-color: var(--color-warning);
    border-color: var(--color-warning);
    color: var(--color-white);
}

.subheader {
    margin-bottom: 30px;
}

.p-breadcrumb {

    .p-menuitem-text,
    .p-breadcrumb-chevron {
        padding: 10px;
        color: var(--color-grey-300);
    }

    .p-element:first-child .p-menuitem-text {
        color: var(--color-secondary);
        font-weight: 500;
    }

    background-color: white !important;
    margin-bottom: 1rem;
    padding: 24px 32px;
    border-radius: 8px;
}

.form-group {
    margin-bottom: 1.75rem;
}

.form-control {
    font-weight: 400 !important;
    background-color: var(--color-white) !important;

    &.disabled,
    &:disabled {
        background-color: #f3f3f3 !important;
    }
}

.clickable-item {
    cursor: pointer;
}

.text-bold {
    font-weight: bold;
}

.text-primary-color {
    color: var(--color-primary-light);
}

label.checkbox>input:checked~span {
    background-color: var(--color-primary-light) !important;
}

.nav .show>.nav-link,
.nav .nav-link:hover:not(.disabled),
.nav .nav-link.active {
    color: var(--color-primary-light) !important;
}

.mark,
mark {
    padding: .2em !important;
    background-color: yellow !important;
}

/* Portlet */

.portlet.light>.portlet-title .inputs.inputs-full-width {
    display: block;
    float: none;
}

/* Tree */

.tree-item-icon-color {
    color: #dfba49;
}

//Fix for font-awesome usage on menu (issue #17)
.page-sidebar .page-sidebar-menu>li>a>i.fa,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a>i.fa {
    margin-left: 1px;
    margin-right: 4px;
    top: 3px;
}

/* Accordions */
.files-accordion {
    strong {
        padding-right: 5px;
        font-size: 1.25rem;
    }
}

/* Forms */
.form-group label {
    margin-bottom: 0.5rem;
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.row>.col,
.row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-group.form-md-line-input.no-hint {
    margin-bottom: 15px;
}

.form-group.form-md-line-input.has-error .form-control {
    border-bottom-color: red;
}

.form-group.form-md-line-input.has-error .help-block-info {
    display: none;
}

/* Portlets */
.portlet.light>.portlet-title.portlet-title-filter {
    border: none;
    margin-bottom: 0;
}

/* Buttons */

.btn {
    font-weight: 600 !important;
}

.btn i {
    color: #d9dae3 !important;
    padding-right: 0.15rem !important;
}

.btn.btn-sm i,
.btn-group-sm>.btn i {
    font-size: 1rem !important;
    padding-right: 0px !important;
}

.btn+.btn {
    margin-left: 5px;
}

/* Override btn colors */
.btn-danger {
    background-color: var(--color-danger) !important;
    border-color: var(--color-danger) !important;

    &:hover {
        background-color: var(--color-danger-light) !important;
        border-color: var(--color-danger-light) !important;
    }

    &:disabled {
        opacity: 0.4 !important;
        cursor: not-allowed !important;
        pointer-events: all !important;
    }

    &:active {
        background-color: var(--color-danger-dark) !important;
        border-color: var(--color-danger-dark) !important;
    }
}

.btn-primary,
.swal2-styled.swal2-confirm {
    background-color: var(--color-primary-light) !important;
    color: var(--color-text) !important;
    border-color: var(--color-primary-light) !important;

    &:hover {
        background-color: var(--color-primary-light) !important;
        border-color: var(--color-primary-light) !important;
    }

    &:disabled {
        opacity: 0.4 !important;
        cursor: not-allowed !important;
        pointer-events: all !important;
    }

    &:active {
        background-color: var(--color-primary-dark) !important;
        border-color: var(--color-primary-dark) !important;
    }

    i {
        color: var(--color-text) !important;
    }
}

.btn-secondary,
.btn-default,
.swal2-styled.swal2-cancel {
    background-color: var(--color-secondary) !important;
    color: var(--color-primary-dark) !important;
    border-color: var(--color-secondary) !important;

    &:hover {
        background-color: var(--color-secondary-light) !important;
        border-color: var(--color-secondary-light) !important;
    }

    &:disabled {
        opacity: 0.4 !important;
        cursor: not-allowed !important;
        pointer-events: all !important;
    }

    &:active {
        background-color: var(--color-secondary-dark) !important;
        border-color: var(--color-secondary-dark) !important;
    }

    i {
        color: var(--color-primary-dark) !important;
    }
}

.btn-outline-primary {
    color: var(--color-text-dark) !important;
    border-color: var(--color-primary-light) !important;

    &:hover {
        background-color: var(--color-primary-light) !important;
        border-color: var(--color-primary-light) !important;
    }

    &:disabled {
        opacity: 0.4 !important;
        cursor: not-allowed !important;
        pointer-events: all !important;
    }

    &:active {
        background-color: var(--color-primary-dark) !important;
        border-color: var(--color-primary-dark) !important;
    }

    i {
        color: var(--color-text-dark) !important;
    }
}

.btn-outline-secondary {
    color: var(--color-secondary-dark) !important;
    border: 1px solid var(--color-secondary) !important;

    &:hover {
        background-color: var(--color-secondary-light) !important;
        border-color: var(--color-secondary-light) !important;
        color: var(--color-primary-dark) !important;

        i {
            color: var(--color-primary-dark) !important;
        }
    }

    &:disabled {
        opacity: 0.4 !important;
        cursor: not-allowed !important;
        pointer-events: all !important;
    }

    &:active {
        background-color: var(--color-secondary-dark) !important;
        border-color: var(--color-secondary-dark) !important;
    }

    i {
        color: var(--color-secondary-dark) !important;
    }
}

/* END - Override btn colors */

/* Date picker */

.theme-red .bs-datepicker-head {
    background-color: var(--color-form);
}

.theme-red .bs-datepicker-predefined-btns button.selected {
    background-color: var(--color-form);
}

.theme-red .bs-datepicker-body table td span.selected,
.theme-red .bs-datepicker-body table td.selected span,
.theme-red .bs-datepicker-body table td span[class*="select-"]:after,
.theme-red .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: var(--color-form);
}

.theme-red .bs-datepicker-body table td.week span {
    color: var(--color-form);
}

.theme-red .bs-datepicker-body table td.active-week span:hover {
    cursor: pointer;
    background-color: var(--color-form);
    color: var(--color-white);
}

/* FamFamFam Flags */
.famfamfam-flags {
    display: inline-block;
}

/* Social Login Icons */
.social-icons li .microsoft {
    background: url(../../assets/common/images/social/microsoft.png) no-repeat;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

/* Material Design form input angular validation colors */

.form-group.form-md-line-input .form-control.ng-touched.ng-invalid {
    border-bottom-color: red;
}

.form-group.form-md-line-input .form-control.ng-touched.ng-invalid.edited:not([readonly])~label:after {
    background-color: red;
}

.page-footer-inner {
    padding-bottom: 10px;
}

.md-shadow-zero-light {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.18) !important;
}

/* ag-grid overrides */

.ag-theme-material .ag-icon-checkbox-checked {
    color: var(--color-primary-light);
}

.ag-theme-material .ag-icon {
    color: var(--color-primary-light);
    font-size: 20px;
    font-weight: 600;
}

body .ui-table .ui-table-thead>tr>th {

    color: var(--color-primary-light);
    background-color: var(--color-table);
}

/* even in RTL languages below types are LTR */
.input-ltr,
input[type="email"],
input[type="url"],
input[type="number"],
input[type="tel"] {
    direction: ltr;
}

.form-group.form-md-line-input .form-control.edited:not([readonly]):not(:focus)~.help-block {
    opacity: 1;
}

/* JS Tree */
.jstree-default .jstree-icon,
.jstree-default-small .jstree-icon,
.jstree-default-large .jstree-icon {
    background-image: url("../../assets/common/images/32px.png");
}

#jstree-dnd.jstree-default .jstree-er,
#jstree-dnd.jstree-default-small .jstree-er,
#jstree-dnd.jstree-default-large .jstree-er {
    background-image: url("../../assets/common/images/32px.png");
    background-repeat: no-repeat;
    background-color: transparent;
}

.jstree-default .jstree-themeicon-custom {
    background-image: none;
}

/* Make user menu wider */
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu {
    width: 250px;
}

/* Fix for issue #407 */
br {
    font-family: Verdana, sans-serif;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.current .step-indicator {
    background-color: #368cdc;
}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.current .step-indicator {
    background-color: #368cdc;
}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li.current .step-indicator {
    border: 2px solid #368cdc;
}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.current .step-indicator {
    background-color: #368cdc;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.current .step-indicator {
    border: 2px solid #368cdc;
    color: #368cdc;
}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.current .step-indicator {
    background-color: #368cdc;
}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li.current .step-indicator {
    background-color: #368cdc;
}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li.current .step-indicator {
    border: 2px solid #368cdc;
}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li.current .step-indicator {
    background-color: #368cdc;
}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li.current .step-indicator {
    border: 2px solid #368cdc;
    color: #368cdc;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.navigable.current a:hover .step-indicator {
    background-color: #1975d2;
}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.navigable.current a:hover .step-indicator {
    background-color: #1975d2;
}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.navigable.current a:hover .step-indicator {
    background-color: #1975d2;
}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.navigable.current a:hover .step-indicator {
    background-color: #1975d2;
}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li.navigable.current a:hover .step-indicator {
    background-color: #1975d2;
}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li.navigable.current a:hover .step-indicator {
    background-color: #1975d2;
}

.offcanvas.offcanvas-right.offcanvas-on {
    visibility: visible !important;
    transition: none !important;
    -webkit-transition: none !important;
}

.offcanvas.offcanvas-on {
    transition: none !important;
    -webkit-transition: none !important;
}

.offcanvas {
    transition: none !important;
    -webkit-transition: none !important;
}

.form-check-input {
    &:checked {
        background-color: var(--color-primary-light);
        border-color: var(--color-primary-light);
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem rgba(75, 9, 15, 28%) !important;
        border-color: var(--color-primary-light) !important;
    }
}