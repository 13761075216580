.u-color-corporate-gold {

    &,
    * {
        color: var(--color-secondary);
    }
}

.u-color-black {

    &,
    * {
        color: var(--color-black);
    }
}

.u-color-white {

    &,
    * {
        color: var(--color-white);
    }
}

.disabled {
    opacity: .5;
}