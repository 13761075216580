@media print {

    .noPrint,
    [data-print="none"] {

        &,
        &[data-print="none"] {
            display: none !important;
        }
    }
}